<template>
  <div class="admin-stats-managers">
    <div class="alert">
      <i class="fa fa-info-circle"></i>
      <span>&nbsp; 2022년도 이후 후원형 프로젝트를 2건 이상 오픈한 진행자의 데이터 (프로젝트 시작일 기준)</span>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th>번호</th>
        <th>진행자명</th>
        <th v-for="year in state.years" :key="year">{{ year }}년</th>
        <th>합계</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(seq, idx) in state.sequences" :key="seq">
        <td>{{ state.sequences.length - idx }}</td>
        <td>
          <a :href="`/channel/${seq}`" target="_blank">{{ state.names[seq] }}</a>
        </td>
        <template v-for="year in state.years">
          <td>{{ state.counts[seq][year] || 0 }}개</td>
        </template>
        <td>{{ getTotalCount(seq) }}개</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {reactive} from "@vue/composition-api";
import http from "../../../scripts/http";

function Component(initialize) {
  this.name = "pageAdminStatsUtm";
  this.initialize = initialize;
}

export default {
  components: {},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      init();
    });

    const state = reactive({
      loaded: false,
      years: [],
      sequences: [],
      counts: {},
      names: {},
    });

    const getTotalCount = (seq) => {
      let output = 0;

      for (let i = 0; i < state.years.length; i++) {
        output += state.counts[seq][state.years[i]] || 0;
      }

      return output;
    };

    const init = () => {
      state.loaded = false;
      http.get("/api/admin/stats/annual/managers").then(({data}) => {
        state.loaded = true;

        const years = new Set();
        const sequences = new Set();

        for (let i = 0; i < data.body.length; i++) {
          const manager = data.body[i];
          const year = manager.investStartYear;
          const sequence = manager.memberSeq;
          const count = manager.yearlyCount;

          years.add(year);
          sequences.add(sequence);

          !state.names[sequence] && (state.names[sequence] = manager.memberName);
          !state.counts[sequence] && (state.counts[sequence] = {});
          !state.counts[sequence][year] && (state.counts[sequence][year] = count);
        }

        state.years = Array.from(years);
        state.sequences = Array.from(sequences);
        console.log(state);
      });
    };

    return {
      component,
      state,
      getTotalCount,
    };
  }
};
</script>

<style lang="scss" scoped>
.admin-stats-managers {
  .alert {
    background: #f7f7f7;
  }

  table {
    margin-bottom: $px20;

    th {
      background: #f7f7f7;
    }

    td a {
      color: $colorAnchor;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>